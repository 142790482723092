<template>
  <validation-observer
    ref="resourceSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="selectRoomModal"
      ref="selectRoomModal"
      body-class="position-static"
      centered
      size="l"
      :title='`Chọn phòng - Tổng thí sinh: ${countStudent}`'
      :no-close-on-backdrop="true"
      @hide="onHide"
      @show="onShow"
    >
      <b-row>
        <b-col cols="12">
          <b-form
            ref="formRef"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in examRooms"
              :key="item.id"
              ref="row"
            >
              <b-col md="10">
                <b-form-group label-for="room">
                  <template v-slot:label>
                    Phòng thi <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Loại yêu cầu"
                    rules="required"
                  >
                    <v-select
                      v-model="item.rooms"
                      :options="roomOptions"
                      @input="event => onSelectRoomChange(event, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="2"
                md="1"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeatAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid || examRooms.length === 0"
            variant="primary"
            class="mr-1"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('selectRoomModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCol, BForm, BFormGroup, BModal, BOverlay, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'SelectRoomModal',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [heightTransition],
  props: {
    currentSelectedRoom: {
      type: Object,
      default: undefined,
    },
    countStudent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      examRooms: [{
        id: 1,
        rooms: null,
      }],
      nextId: 2,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
    }
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    ...mapGetters({
      allRoom: 'cdsphoabinhExaminationRoom/allRoom',
    }),
    roomOptions() {
      return this.allRoom.map(item => ({ value: item.id, label: `${item.name} (SL: ${item.maxQty})`, maxQty: item.maxQty }))
    },
  },
  methods: {
    ...mapActions({
      getAllRooms: 'cdsphoabinhExaminationRoom/getAllRooms',
    }),
    repeatAgain() {
      this.examRooms.push({
        id: this.nextId++,
        rooms: null,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.examRooms.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formRef.scrollHeight)
      })
    },
    async onShow() {
      if (this.currentSelectedRoom) {
        this.examRooms = this.currentSelectedRoom
        this.nextId = this.currentSelectedRoom.length + 1
      }
      this.isLoading = true
      try {
        await this.getAllRooms()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.examRooms = [{
        id: 1,
        rooms: null,
      }]
      this.nextId = 2
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onSelectRoomChange(event, index) {
      this.examRooms[index] = {
        id: index + 1,
        rooms: event,
      }
    },
    async onSave(type = null) {
      this.$emit('handleRoom', this.examRooms)
      this.$bvModal.hide('selectRoomModal')
    },

  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
